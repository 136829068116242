import { render, staticRenderFns } from "./Builder_AddOptionIcon.vue?vue&type=template&id=cc3cb5ba&scoped=true&functional=true&"
import script from "./Builder_AddOptionIcon.vue?vue&type=script&lang=js&"
export * from "./Builder_AddOptionIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "cc3cb5ba",
  null
  
)

export default component.exports