<template functional>
  <svg width="16" height="16" viewBox="0 0 24 24" fill="#f85061" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" width="24" height="24" fill="white"/>
    <rect opacity="0.01" x="1.2002" y="1.19995" width="21.6" height="21.6" fill="#f85061"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.58085 1.85293C8.78095 1.45274 9.18998 1.19995 9.63741 1.19995H14.3625C14.8099 1.19995 15.2189 1.45274 15.419 1.85293L15.7676 2.54995H20.2685C20.9209 2.54995 21.4498 3.07881 21.4498 3.7312V4.0687C21.4498 4.72109 20.9209 5.24995 20.2685 5.24995H3.73107C3.07868 5.24995 2.5498 4.72109 2.5498 4.0687V3.7312C2.5498 3.07881 3.07868 2.54995 3.73107 2.54995H8.23234L8.58085 1.85293ZM3.81023 6.74995H20.1897L19.0844 21.7049C19.0392 22.3222 18.5252 22.8 17.9063 22.8H6.09361C5.47466 22.8 4.96066 22.3222 4.91549 21.7049L3.81023 6.74995Z" fill="#f85061"/>
  </svg>
</template>

<script>
export default {
  name: 'BasketIcon'
}
</script>

<style scoped>

</style>
