<template functional>
  <svg width=16 height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" width="24" height="24" fill="white"/>
    <path d="M8.0311 19.9395C5.14196 17.9613 4.27573 17.1017 3.41345 16.2388C2.50879 15.3334 1.94599 14.4903 1.61431 13.6895C1.28014 12.8826 1.20022 12.1656 1.2002 11.5482V3.61565C1.2002 3.18847 1.49463 2.81764 1.91069 2.72081L8.34191 1.2241C8.41087 1.20805 8.48072 1.20019 8.55016 1.2002V20.1002C8.36815 20.1002 8.18682 20.0461 8.0311 19.9395Z" fill="#CBE8E2"/>
    <path d="M9.06949 19.9395C11.9586 17.9613 12.8249 17.1017 13.6871 16.2388C14.5918 15.3334 15.1546 14.4903 15.4863 13.6895C15.8204 12.8826 15.9004 12.1656 15.9004 11.5482V3.61565C15.9004 3.18847 15.606 2.81764 15.1899 2.72081L8.75867 1.2241C8.68972 1.20805 8.61986 1.20019 8.55042 1.2002V20.1002C8.73244 20.1002 8.91376 20.0461 9.06949 19.9395Z" fill="#CBE8E2"/>
    <path d="M11.4803 21.2891C8.59118 19.3109 7.72494 18.4513 6.86267 17.5884C5.95801 16.6831 5.39521 15.8399 5.06353 15.0391C4.72936 14.2322 4.64943 13.5152 4.64941 12.8979V4.96526C4.64941 4.53807 4.94385 4.16725 5.35991 4.07042L11.7911 2.57371C11.8601 2.55766 11.9299 2.5498 11.9994 2.5498V21.4498C11.8174 21.4498 11.636 21.3958 11.4803 21.2891Z" fill="#94D3C3"/>
    <path d="M12.5187 21.2891C15.4078 19.3109 16.2741 18.4513 17.1364 17.5884C18.041 16.6831 18.6038 15.8399 18.9355 15.0391C19.2697 14.2322 19.3496 13.5152 19.3496 12.8979V4.96526C19.3496 4.53807 19.0552 4.16725 18.6391 4.07042L12.2079 2.57371C12.1389 2.55766 12.0691 2.5498 11.9996 2.5498V21.4498C12.1817 21.4498 12.363 21.3958 12.5187 21.2891Z" fill="#94D3C3"/>
    <path d="M14.9315 22.6387C12.0423 20.6605 11.1761 19.8009 10.3138 18.938C9.40918 18.0327 8.84638 17.1895 8.5147 16.3887C8.18053 15.5818 8.10061 14.8648 8.10059 14.2475V6.31487C8.10059 5.88768 8.39502 5.51686 8.81108 5.42003L15.2423 3.92332C15.3113 3.90727 15.3811 3.89941 15.4506 3.89941V22.7994C15.2685 22.7994 15.0872 22.7454 14.9315 22.6387Z" fill="#29A687"/>
    <path d="M15.9699 22.6387C18.859 20.6605 19.7253 19.8009 20.5875 18.938C21.4922 18.0327 22.055 17.1895 22.3867 16.3887C22.7208 15.5818 22.8008 14.8648 22.8008 14.2475V6.31487C22.8008 5.88768 22.5063 5.51686 22.0903 5.42003L15.6591 3.92332C15.5901 3.90727 15.5203 3.89941 15.4508 3.89941V22.7994C15.6328 22.7994 15.8142 22.7454 15.9699 22.6387Z" fill="#29A687"/>
    <circle cx="18" cy="18" r="6" fill="#94D3C3"/>
    <rect opacity="0.01" x="13.2002" y="13.2002" width="9.6" height="9.6" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0001 14.1602C18.232 14.1602 18.4201 14.3482 18.4201 14.5802V21.4202C18.4201 21.6521 18.232 21.8402 18.0001 21.8402C17.7681 21.8402 17.5801 21.6521 17.5801 21.4202V14.5802C17.5801 14.3482 17.7681 14.1602 18.0001 14.1602Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9004 17.9998C21.9004 18.2483 21.7094 18.4498 21.4738 18.4498L14.527 18.4498C14.2914 18.4498 14.1004 18.2483 14.1004 17.9998C14.1004 17.7513 14.2914 17.5498 14.527 17.5498L21.4738 17.5498C21.7094 17.5498 21.9004 17.7513 21.9004 17.9998Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'Builder_AddTemplateIcon'
}
</script>
