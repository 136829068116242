<template functional>
  <svg width="24" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" width="24" height="24" fill="white"/>
    <path d="M11.336 22.5936C8.08329 20.358 7.10341 19.3831 6.12862 18.404C5.10187 17.3726 4.45758 16.4065 4.07634 15.4825C3.69189 14.5508 3.60012 13.7224 3.6001 13.0125V4.06869C3.6001 3.51939 3.97707 3.04257 4.50972 2.91814L11.7337 1.23064C11.8219 1.21004 11.9113 1.19995 12.0001 1.19995V22.8C11.7672 22.7999 11.5353 22.7305 11.336 22.5936Z" fill="#29A687"/>
    <path d="M12.6641 22.5936C15.9168 20.358 16.8967 19.3831 17.8714 18.404C18.8982 17.3726 19.5424 16.4065 19.9237 15.4825C20.3081 14.5508 20.3999 13.7224 20.3999 13.0125V4.06871C20.3999 3.5194 20.0229 3.04259 19.4903 2.91816L12.2664 1.23066C12.1782 1.21005 12.0888 1.19994 11.9999 1.19995V22.8C12.2328 22.8 12.4648 22.7306 12.6641 22.5936Z" fill="#29A687"/>
    <circle cx="18" cy="18" r="6" fill="#94D3C3"/>
    <rect opacity="0.01" x="13.1997" y="13.2001" width="9.6" height="9.6" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9996 14.1602C18.2315 14.1602 18.4196 14.3482 18.4196 14.5802V21.4202C18.4196 21.6521 18.2315 21.8402 17.9996 21.8402C17.7676 21.8402 17.5796 21.6521 17.5796 21.4202V14.5802C17.5796 14.3482 17.7676 14.1602 17.9996 14.1602Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8398 18.0001C21.8398 18.232 21.6518 18.4201 21.4198 18.4201L14.5798 18.4201C14.3479 18.4201 14.1598 18.232 14.1598 18.0001C14.1598 17.7681 14.3479 17.5801 14.5798 17.5801L21.4198 17.5801C21.6518 17.5801 21.8398 17.7681 21.8398 18.0001Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'Builder_AddOptionIcon'
}
</script>

<style scoped>

</style>
