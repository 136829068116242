<template lang="pug">
.skill-table
  v-card-head
    v-card-head-label
      h2.title {{ 'pages.administration.skills' | translate }}
      p.subtitle {{ 'pages.administration.skills_managment_description' | translate }}
    v-card-head-label
      span.link-text(@click="$refs.tableTree.collapseAll()")
        | {{ 'ui.buttons.collapse_all' | translate }}
  vue-scroll(:ops="ops")
    v-table( ref="skillTable")
      v-table-head
        th.table-page__table-header
          span(style="width:100%") {{ 'ui.labels.name' | translate }}
      v-table-body(
        :cols="1"
        :empty="!items.length"
        :loaded="loaded"
        :no-result="!items.length")
        v-scrollable(
          :bottom-offset="10"
          :min-height="minHeightTable")
          v-table-tree(
            ref="tableTree"
            v-model="items"
            allow-toggle-branch
            hide-last-root-element
            :external-dragging="allowDrag"
            :allowMultiselect="false"
            :allowCursorBeforeAfter="false"
            @select="onSelect"
            @drop="onDrop"
            @externaldragover="onDragStart"
            style="width:100%; min-height: 50vh")
            template(
              slot="toggle"
              slot-scope="{ node }")
              .sl-vue-tree__chevron
                v-chevron(
                  list-mode
                  :value="node.isExpanded")
            template(
              slot="title"
              slot-scope="{ node }")
              category-circle(
                v-if="!node.isLeaf"
                :color="setColor(node)")
              span.sl-vue-tree__node-icon(
                v-if="node.isLeaf")
                badge(
                  :width="28"
                  :height="34"
                  :color="setColor(node)"
                  :level="node.data.starsCount")
              span.sl-vue-tree__node-title.mr-2
                v-editable(
                  ghost
                  toggle-by-icon-only
                  :init-state="node.isEditing"
                  :error-text="node.data.errorText"
                  pencil-class="v-table-tree--show-on-hover"
                  @close="onTitleUpdate($event, node)")
                  span {{ node.title }}
            template(
              slot="sidebar"
              slot-scope="{ node }")
              .sidebar-controls.v-table-tree--show-on-hover(
                @mousedown.stop
                @mouseup.stop)
                v-dropdown(
                  v-if="!node.isLeaf && allowAddBadge(node) && isRootNode(node)"
                  right
                  width-auto
                  auto-close)
                  v-btn(icon @click="getTemplates")
                    add-template-icon
                  template(slot="content")
                    v-card(
                      elevation
                      style="width: 220px")
                      v-menu
                        v-menu-item(
                          v-for="item in templates"
                          :key="item.id"
                          @click="onAddBadgeByTemplate(node, item.id)")
                          span.v-menu-item_text {{ item.name }}
                          .lineend(@click.stop)
                            v-btn(
                              icon
                              gray
                              @click="openTemplateEditModal({ id: item.id })")
                              i.fa.fa-edit
                            v-btn(
                              icon
                              danger
                              @click="deleteTemplate(item.id)")
                              i.fa.fa-trash-alt
                        v-menu-item(
                          @click="createTemplate")
                          | + {{ 'pages.builder.new_template' | translate}}
                v-dropdown(
                  v-if="!node.isLeaf"
                  right
                  width-auto
                  auto-close)
                  v-btn(icon)
                    add-option-icon
                  template(slot="content")
                    v-card(
                      elevation
                      style="width: 220px")
                      v-menu
                        v-menu-item(
                          :disabled="!allowAddBadge(node) || !isRootNode(node)"
                          @click="onAddBadge(node)")
                          | {{ 'ui.buttons.add_badge' | translate }}
                        v-menu-item(
                          :disabled="!allowAddCategory(node)"
                          @click="onAddCategory(node)")
                          | {{ 'ui.buttons.add_category' | translate }}
                        v-menu-item(
                          :disabled="!allowAddBadge(node) || !isRootNode(node)"
                          @click="onAddGradationalBadge(node)")
                          | {{ 'ui.buttons.add_gradational_badge' | translate }}
                v-btn(
                  v-if="isRootNode(node)"
                  warning
                  icon
                  stop-propagation
                  @click="onRemove(node)"
                  @mousedown.native="onBeforeRemove(node)")
                  basket-icon()
  template-detail(
    v-if="templateModal"
    :index="templateModal.index")
</template>

<script>
import { NODE_TYPE, MODAL_TYPES, COLOR_PRIMARY } from '@/util/constants.js'
import { mapActions, mapGetters } from 'vuex'

import Badge from '@/components/svg/Badge'
import TemplateDetail from '@/components/modals/builder/templates/TemplateDetail'
import addTemplateIcon from '@/components/svg/Builder_AddTemplateIcon'
import addOptionIcon from '@/components/svg/Builder_AddOptionIcon'
import BasketIcon from '@/components/svg/global/BasketIcon'
import CategoryCircle from '@/components/svg/CategoryCircle'

export default {
  name: 'Table',

  components: {
    Badge,
    TemplateDetail,
    addTemplateIcon,
    addOptionIcon,
    BasketIcon,
    CategoryCircle
  },

  async mounted () {
    await this.loadSkills({ lazy: true })
    this.loaded = true
    if (this.badgeId !== null) {
      this.autoOpenNode(this.badgeId, this.items[0].children[0], true)
    }
    this.$nextTick(() => {
      this.minHeightTable = this.$refs.skillTable.$el.clientHeight + 10
    })
    if (this.isLoadLazyTree) {
      this.loadSkills({ lazy: false })
    }
  },

  data: () => ({
    minHeightTable: 0,
    ops: {
      scrollPanel: {
        scrollingX: true
      },
      rail: {
        gutterOfEnds: '15px'
      }
    },
    loaded: false,
    templateBadgeCreated: null,
    allowDrag: true,
    selectedNode: null
  }),

  methods: {
    ...mapActions('modals', ['openConfirmModal']),

    ...mapActions('skills', ['selectItem', 'addItem', 'loadSkills']),

    ...mapActions('trees', ['getNodeById', 'createNode', 'updateNode', 'removeNode', 'moveBadge']),

    ...mapActions('organization', [
      'moveClipboardItemsTo',
      'onMoveItems',
      'copyPosition'
    ]),

    ...mapActions('badges/templates', [
      'selectTemplate',
      'createTemplate',
      'updateTemplate',
      'deleteTemplate',
      'getById'
    ]),

    ...mapActions('builder', ['openTemplateEditModal']),

    allowAddBadge (node) {
      return !node.children.some((item) => {
        return item.data.typeId === NODE_TYPE.CATEGORY
      })
    },

    allowAddCategory (node) {
      return !node.children.some((item) => item.data.typeId !== NODE_TYPE.CATEGORY)
    },

    onAddCategory (toNode) {
      this.addItem({ toNode, typeId: NODE_TYPE.CATEGORY })
    },

    onAddBadge (toNode) {
      this.addItem({ toNode, typeId: NODE_TYPE.BADGE })
    },

    async onAddBadgeByTemplate (toNode, templateId) {
      this.templateBadgeCreated = templateId
      this.addItem({ toNode, typeId: NODE_TYPE.BADGE })
    },

    onAddGradationalBadge (toNode) {
      this.addItem({ toNode, typeId: NODE_TYPE.GRADATIONAL_BADGE })
    },

    async onTitleUpdate (value, item) {
      item.title = value
      const node = await this.getNodeById(item.data.id)
      if (item.data.isNew) {
        const toNode = await this.getNodeById(item.data.parentId)
        const payload = {
          name: value,
          typeId: item.data.typeId,
          useOutsideVisualTree: true,
          toNode,
          templateId: this.templateBadgeCreated ? this.templateBadgeCreated : null
        }
        this.templateBadgeCreated = null
        this.createNode(payload)
        item.data.isNew = false
      } else {
        const props = {
          name: value
        }
        this.updateNode({ node, props })
      }
    },

    onSelect (nodes) {
      this.selectItem(nodes[0])
      this.selectedNode = nodes[0]
    },

    onDrop (nodes, params) {
      this.moveBadge({ categoryId: params.node.data.id, badgeId: nodes[0].data.id })
    },

    onBeforeRemove (node) {
      if (node.data.isNew) {
        node.data.isDeleted = true
      }
    },

    onRemove (node) {
      if (!node.children.length && !node.data.employeeCount) {
        this.removeNode({ node: { props: { ...node.data } } })
      } else {
        let subtitle
        let title
        if (!node.isLeaf) {
          subtitle = this.$t('infotext.delete_not_empty_category')
          title = this.$t('ui.labels.warning') + '!'
        } else {
          title = this.$t('ui.modals.delete_message_with_params', {
            name: node.data.name
          })
        }

        const options = {
          content: {
            title: title,
            subtitle: subtitle
          },

          props: {
            type: node.isLeaf ? MODAL_TYPES.WARNING_ORANGE : MODAL_TYPES.WARNING_RED
          },

          buttons: {
            apply: {
              label: 'ui.buttons.delete',
              callback: this.removeNode,
              params: { node: { props: { ...node.data } } }
            }
          }
        }

        this.openConfirmModal(options)
      }
    },

    async getTemplates () {
      await this.$store.dispatch('badges/templates/loadTemplates', null, { root: true })
    },
    setColor (node) {
      let color = node.data.color

      function findID (arr, ID) {
        const item = arr.find((el) => el.id === ID)
        if (item && item.color) {
          return item
        } else if (item.id === 1) {
          return { color: '#13b389' }
        } else {
          return findID(arr, item.parentId)
        }
      }
      if ((node.data.id !== 1 && !color) || (node.data.id !== 1 && color && (node.data.typeId === NODE_TYPE.BADGE || node.data.typeId === NODE_TYPE.GRADATIONAL_BADGE))) {
        const findElem = findID(this.arrAllGlobalTree, node.data.parentId)
        if (findElem && findElem?.color) {
          color = findElem.color
        }
      }

      return color || this.colorPrimary
    },

    isRootNode (node) {
      return node.data.parentId
    },
    onDragStart ({ node }, event) {
      if (this.selectedNode.data.id === node.data.id) return
      if (this.selectedNode !== null) {
        if (this.selectedNode.data.typeId === NODE_TYPE.CATEGORY) {
          if ((!node.children.length && node.data.typeId === NODE_TYPE.CATEGORY) || (node.data.typeId === NODE_TYPE.CATEGORY && !node.children.some(el => el.data.typeId !== this.selectedNode.data.typeId))) {
              this.allowDrag = false
            } else {
              this.allowDrag = true
            }
        } else {
          if (!node.children.length || node.children.some(el => el.data.typeId !== NODE_TYPE.CATEGORY)) {
            this.allowDrag = false
          } else {
            this.allowDrag = true
          }
        }
      }
    },

    getNodeTree (nodeID, tree) {
      let result = null
        const findNodeWithTree = (nodeID, tree) => {
          const isSubBadges = tree.data.gradationalSubBadges.find(el => el.id === nodeID)
          if (tree.data.id === nodeID || isSubBadges) {
            result = tree
          } else {
            tree.children.forEach(element => {
              findNodeWithTree(nodeID, element)
            })
          }
       }
        findNodeWithTree(nodeID, tree)
        return result
    },

    autoOpenNode (nodeID, tree, status = true) {
      if (!nodeID) return
      const autoOpenParentNode = (parentId) => {
        if (parentId) {
          let result = this.getNodeTree(parentId, tree)
           result.isExpanded = status
           result.isSelected = false
           autoOpenParentNode(result.data.parentId)
        }
      }

      let result = this.getNodeTree(nodeID, tree)
      if (result) {
        result.isExpanded = status
        result.isSelected = status
        autoOpenParentNode(result.data.parentId)
        if (status) {
          this.onSelect([result])
        }
      }
    }
  },

  computed: {
    items: {
      get () {
        return this.$store.getters['skills/items']
      },

      set (value) {
        this.$store.commit('skills/SET_ITEMS', value)
      }
    },

    ...mapGetters('skills', ['selected', 'arrAllGlobalTree']),

    ...mapGetters('nodeTmp', ['cursorNode']),

    ...mapGetters('badges/templates', ['templates']),

    ...mapGetters('builder', ['templateModal']),

    ...mapGetters('trees', ['globalTree', 'isLoadLazyTree']),

    route () {
      return this.$route.path
    },

    badgeId () {
      return parseInt(this.$route.params?.badge_id) || null
    },

    colorPrimary () {
      return COLOR_PRIMARY
    }
  },

  watch: {
    route () {
      this.selectItem(null)
    },
    badgeId (newBadgeId, oldBadgeId) {
      if (oldBadgeId !== null) {
        this.autoOpenNode(oldBadgeId, this.items[0].children[0], false)
      }
      this.autoOpenNode(newBadgeId, this.items[0].children[0], true)
    }
  }
}
</script>

<style lang="scss" scoped>
.skill-table {
  height: 100%;
  &__table-header {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }

  .sidebar-column {
    width: 120px;
    text-align: right;
  }

  .sidebar-controls {
    width: 130px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
<style lang="scss">
.skill-table {
  .v-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_text{
        max-width: 130px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
  }

  .sl-vue-tree-nodes-list {
    overflow: unset;
  }

  .sl-vue-tree__node-title {
    margin-left: 10px;
  }
}
</style>
<style>
.__rail-is-vertical:before {
  right: 0 !important;
}
</style>
